.order-detail {
    color: #444444;
    strong {
        font-weight: 500;
        color: #111111;
    }
}

.order-detail-table {
    table {
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
    }
    .ant-table-thead > tr > th {
        color: #ffffff !important;
        background-color: #59aae4 !important;
    }
}

.order-status {
    position: relative;
    padding-left: 12px;
    &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #ffa200;
    }

    &--FINISH:before {
        background-color: #11d713;
    }

    &--CANCELED:before {
        background-color: #6c757d;
    }
    &--DELIVERED:before {
        background-color: #0048ad;
    }
}

/*------------create order page------------*/

.create-order {
    .title-box {
        color: $color--primary;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
    }

    .create-order-btn {
        background: #078aff;
        border-color: #078aff;
    }
}

.hidden_input {
    .ant-form-item-control-input {
        display: none;
    }
}
.order-detail-tab-package {
    .ant-tabs-tab-btn {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 1.5em;
        text-transform: uppercase !important;
    }
    .ant-tabs-nav::before {
        border-bottom: unset !important;
    }
}
.histories_order {
    .ant-timeline.ant-timeline-label .ant-timeline-item-label {
        width: calc(30% - 12px);
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-tail {
        left: 32%;
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-head {
        left: 32%;
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
        left: calc(35% - 4px);
        width: calc(65% - 14px);
        text-align: left;
    }
}

.payment-info-order {
    .ant-timeline {
        margin-top: 25px;
    }
    .ant-timeline-item-last {
        padding-bottom: 0 !important;
    }
}

.ant-tabs.customize-order-ant-tabs {
    overflow: visible !important;
    &.ant-tabs-top > .ant-tabs-nav {
        margin: 0 !important;
        font-weight: 500 !important;
    }
    .ant-tabs-content-holder {
        padding: 0.5rem 1.5rem 1.5rem 1.5rem !important;
        background: white;
        border-radius: 0 12px 12px 12px;
    }
    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        border: none;
        border-radius: 12px 12px 0 0;
        margin-right: 5px;
        padding: 10px 24px !important;
        color: $txt-color--label !important;
        background-color: #eff0f6;
    }
    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active.ant-tabs-tab,
    &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active.ant-tabs-tab {
        border: none;
        color: #000000 !important;
        background-color: #ffffff;
    }
}

//-------- order picking ------------
.order-box-list {
    display: flex;
    &__item {
        padding: 10px;
        border: 1px solid #6c757d66;
        display: flex;
        flex-direction: column;
        flex: 1;
        @include media-breakpoint-up(sm) {
            margin-right: 1rem;
        }
        .ant-tag {
            border-radius: 14px;
            font-weight: 500;
        }
    }
}

.order-picking {
    .ant-progress-inner {
        background-color: #e5e5e5;
        .ant-progress-bg {
            height: 10px !important;
        }
    }
    .btn-process {
        button {
            font-weight: 500;
            padding: 4px 0;
            color: #0048ad;
            &:first-child {
                color: #c92d2d;
            }
            &:hover,
            &:focus {
                background-color: inherit;
                color: #7b8894;
            }
        }
    }
    .box-input-fake {
        width: 90px;
        display: inline-block;
        padding: 6px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
    }
}

.picking-step {
    img.ant-image-img {
        @include media-breakpoint-up(lg) {
            height: 50%;
            width: 60%;
        }
    }
}

.modal-pickup-continue {
    .ant-modal-header,
    .ant-modal-footer {
        border: unset !important;
    }
}

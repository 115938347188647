.upload-banner {
    &-img {
        box-shadow: 0 0.2rem 1.2rem 0px rgba($color: #333, $alpha: 0.1);
        transition: all 0.2s;
        padding: 12px;
        border-radius: 8px;
        position: relative;
        text-align: center;
        font-size: 16px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 300px;
            border-radius: 8px;
        }
    }
    &-text {
        margin-top: 4px;
        font-weight: 500;
    }
}

.customize-ant-tabs {
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-content-holder {
    border: 1px solid #e5e5e5;
    border-top: 0;
  }
  > .ant-tabs-nav .ant-tabs-tab {
    border-color: #e5e5e5 !important;
    padding-left: 50px;
    padding-right: 50px;
  }
  > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #ffffff !important;
  }
  .ant-tabs-tab-btn {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.1em;
    text-transform: uppercase !important;
  }
  &.heading-fz-12 .ant-tabs-tab-btn {
    font-size: 1.2em !important;
  }
  &.heading-fz-13 .ant-tabs-tab-btn {
    font-size: 1.3em !important;
  }
  &.heading-fz-14 .ant-tabs-tab-btn {
    font-size: 1.4em !important;
  }
  &.heading-fz-15 .ant-tabs-tab-btn {
    font-size: 1.5em !important;
  }
}

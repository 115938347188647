.create-inventory {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    :global(.ant-select), :global(.ant-btn) {
        width: 200px;
    }
}

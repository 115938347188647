.label {
	line-height: 20px;
	display: inline-block;
	margin-bottom: 5px;
	&.required:before {
		content: "*";
		margin-right: 5px;
		color: #ff0302;
	}
}
.miss-label {
	margin-top: 25px;
}

.text-red {
	 color: $txt-color--redf !important;
}

.text-primary {
	color: $color--primary !important;
}

.text-green {
	color: $txt-color--green8 !important;
}

.text-color-white {
	color: $txt-color--white !important;
}

.text-color-label {
	color: $txt-color--label !important;
}

.text-fz-10 {
	font-size: 10px !important;
}

.text-fz-11 {
	font-size: 11px !important;
}

.text-fz-12 {
	font-size: 12px !important;
}

.text-fz-13 {
	font-size: 13px !important;
}

.text-fz-14 {
	font-size: 14px !important;
}

.text-fz-15 {
	font-size: 15px !important;
}

.text-fz-16 {
	font-size: 16px !important;
}

.text-fz-18 {
	font-size: 18px !important;
}

.text-fz-20 {
	font-size: 20px !important;
}

.cursor-pointer {
	cursor: pointer !important;
}
.font-weight-500{
    font-weight: 500;
}
//@import '~antd/dist/antd.css';
@import 'custom-antd.css';
@import 'bootstrap/bootstrap';
@import 'generics/generic.box-sizing';
@import 'generics/generic.normalize';
@import 'generics/generic.reset';
@import 'settings/setting.color';
@import 'settings/setting.text';
@import 'settings/setting.border';
@import 'settings/setting.table';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'components/layout';
@import 'components/order-detail';
@import 'components/package';
@import 'components/warehouse';
@import 'components/product';
@import 'components/mobile-tab';
@import 'components/drop-down-tab';
@import 'components/stamp';
@import 'common';
@import 'print';
@import 'components/finance';
@import 'components/dropshipping.scss';
@import 'components/upload-banner.scss';
@import 'components/document.scss';
@import 'components/service-manager.scss';
@import 'components/dynamic-table.scss';


$color-text-menu: #cee2ff;
$color-text-breadcrumb: #666666;
$main-menu-width: 80px;
$main-menu-width-expand: 280px;
$config-menu-text-color: rgba(0,0,0,.85);

#root {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: stretch;
}

.ant-layout {
  background-color: #F7F7FC;
}

.site-logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
  cursor: pointer;
}

.site-content {
  padding: 32px 24px;
  background-color: #F7F7FC;
  @include media-breakpoint-down(sm) {
    padding: 10px;
    header.ant-layout-header {
      height: auto;
      padding: 20px 50px;
      line-height: 32px;

      h2 {
        font-size: 16px;
      }
    }
  }
}

.box-shadow {
  box-shadow: 1px 1px 1px rgba(99,99,99,.1);
}

.bg-white {
  background: $bg-color--white;
}

.bg-light-gray {
  background: $bg-color--grayf9;
}

.bg-gray {
  background: $bg-color--grayf2;
}

.bg-blue {
  background: #2e90e8;
}

.bg-orange {
  background: $color--pending-status;
}

.bg-secondary-color {
  background: $color--secondary;
}

#dashboard-body {
  margin-top: 60px;
}

#page-body {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  #page-menu {
    float: left;
    width: $main-menu-width;
    height: 100%;
    position: relative;
    z-index: 3;
    @include media-breakpoint-down(sm) {
      width: 0;
    }
  }
  #page-content {
    float: right;
    width: calc(100% - #{$main-menu-width});
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: auto;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    main {
      @include media-breakpoint-down(sm) {
        margin-top: 70px;
      }
    }
  }
}

.main-menu {
  background: $color--primary;
  color: #ffffff;
  height: 100%;
  overflow: auto;
  box-shadow: 2px 0 10px rgba(0,0,0,.3);
  width: 100%;
  z-index: 1;
  transition: all .3s;
  @include media-breakpoint-down(sm) {
    position: fixed;
    transform: translateX(-110%);
  }
  &--member {
    border-bottom: 1px solid rgba(233, 242, 255, .1);
    height: 70px;
    z-index: 999999;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: inherit;
    background-color: $color--primary;
    &--user > div {
      //border: 1px solid rgba(255, 255, 255, 1);
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
    &--btn {
      cursor: pointer;
      padding: 5px 6px;
      span {
        width: 32px;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        display: block;
        position: relative;
        margin: 15px 0;
        transition: all .5s;
        &:before, &:after {
          content: "";
          border-top: 2px solid #ffffff;
          position: absolute; left: 0;
          width: 100%;
          transition: all .5s;
        }
        &:before {
          top: -11px;
        }
        &:after {
          bottom: -11px;
        }
      }
    }
  }

  &.expanded {
    width: $main-menu-width-expand;
    @include media-breakpoint-down(sm) {
      width: 100%;
      transform: translateX(0);
    }
    .main-menu--member {
      &--user > div {
        display: block;
      }
      &--btn {
        span {
          border-color: transparent;
          &:before {
            transform: rotate(45deg);
            top: -1px;
          }
          &:after {
            transform: rotate(-45deg);
            bottom: -1px;
          }
        }
      }
    }
  }

  &--list {
    &--title {
      color: #9BAFFF;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 42px;
      padding: 0 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &--item {
      background: transparent;
      transition: all .3s;
      margin: 5px 0 0;
      a {
        color: #efefff;
        display: block;
        line-height: 42px;
        padding: 0 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.selected {
          background: #5B73F0;
          color: #ffffff;
        }
      }
      &:hover {
        background: #5B73F0;
        color: #ffffff;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 3px;

    &:horizontal {
      height: 3px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .4);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, .6);
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    &::-webkit-scrollbar {
      width: 3px;

      &:horizontal {
        height: 3px;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: #e2e5ec;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #e2e5ec;
      cursor: pointer;
    }
  }
}

.menu-mobile-btn{
  cursor: pointer;
  padding: 5px 6px;
  span {
    width: 32px;
    border-top: 1px solid $color--primary;
    border-bottom: 1px solid $color--primary;
    display: block;
    position: relative;
    margin: 15px 0;
    transition: all .5s;
    &:before, &:after {
      content: "";
      border-top: 2px solid $color--primary;
      position: absolute; left: 0;
      width: 100%;
      transition: all .5s;
    }
    &:before {
      top: -11px;
    }
    &:after {
      bottom: -11px;
    }
  }
}

.user-info {
	position: relative;
}

.language-box {
    padding: 5px 20px;
	p {
        padding: 10px 0;
		line-height: 32px;
		a {
			color: $config-menu-text-color;
			&:hover {
				color: $color--primary;
			}
		}
	}
}

.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #ffffff !important;
}

/*---------------header----------------*/

.page-header {
  width: 100%;
  height: 70px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, .1);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  padding: 0 20px 5px 20px;

  @include media-breakpoint-down(sm) {
    position: fixed; top: 0; left: 0;
  }

  &.ant-layout-header {
    line-height: 40px;
  }

  >div {
  }

  h2 {
    font-size: 22px;
    font-weight: 500;
    margin-right: 15px;
    padding-right: 15px;
	  position: relative;
	  &:after {
		  content: "";
		  border-left: 1px solid #eeeeee;
		  position: absolute; right: 0; top: 20%;
		  height: 60%;
	  }
  }
}

.breadcrumb-header {
  color: $color-text-breadcrumb;
  position: relative;
  font-size: 13px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    content: "";
  }

  span {
    color: $color-text-breadcrumb;
  }

  a {
    color: $color-text-breadcrumb;

    &:hover {
      color: $color--primary;
    }
  }
}

.navbar {
  display: flex;
  justify-content: space-between;

  .main-navbar {
    display: flex;

    a {
      font-size: 24px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 70px;
      background: rgba(255, 255, 255, .1);
      margin-right: 1px;

      &:hover {
        background: rgba(255, 255, 255, .2);
      }
    }
  }

  .member-navbar {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;

    > a {
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 60px;

      img {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border: 2px solid #ffffff;
      }

      &:hover {
        background: rgba(255, 255, 255, .3);

        img {
          border-color: #ffffff;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    h2 {
      flex-grow: 2;
      font-weight: normal;

      a {
        color: #ffffff;
        display: block;
        line-height: 60px;
        text-align: center;
      }
    }
    .main-navbar {
      flex-grow: 8;
    }
  }
}

.sub-navbar {
  .ant-dropdown-arrow {
    border-top-color: $color--primary;
    border-left-color: $color--primary;
  }

  ul {
    width: 240px;
    background: $color--primary;
    padding: 10px 15px;
    box-shadow: -2px 2px 0 0 rgba(0, 0, 0, .2);

    li {
      & + li {
        border-top: 1px solid rgba(255, 255, 255, .1);
      }

      &:hover {
        background: transparent;
      }

      a {
        color: $color-text-menu;
        line-height: 32px;
        display: block;

        &:hover {
          color: #ffffff;
          background: transparent;
        }
      }
    }
  }
}

.title-page {
  > h2 {
    text-transform: uppercase;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 12px !important;
}

.mh-100 {
  min-height: 100%;
}

.search-reset-btn {
  max-width: calc(55% - 10px);
  margin-right: 10px;
}
.search-submit-btn {
  max-width: 45%;
}

.lading-dropdown-action {
  border: 1px solid #D9DBE9;
  box-shadow: 0 6px 10px #EFF0F6;
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 10px;
  }
  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background: #EAEEFF;
    color: #4551BB;
  }
}

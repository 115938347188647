.finance_order {
  .finance_stats {
    height: 90px;
    padding: 1.2em;
    background-color: #fff;
    display: flex;
    justify-content: start;
    color: #707070;
    border-radius: 8px;
    box-shadow: 0 33px 62px rgba(129, 129, 129, 0.06), 0 13.7866px 25.9021px rgba(129, 129, 129, 0.0431313), 0 7.37098px 13.8485px rgba(129, 129, 129, 0.0357664), 0 4.13211px 7.76336px rgba(129, 129, 129, 0.03), 0 2.19453px 4.12306px rgba(129, 129, 129, 0.0242336), 0 0.913195px 1.7157px rgba(129, 129, 129, 0.0168687);

    .stats_amount {
      margin-top: 8px;
      color: #4551BB;
      font-size: 20px;
      line-height: 28px;

      b {
        font-size: 16px;
      }
    }
  }
}

._summary-document-control-detail {
  //margin-top: 42px !important;
}
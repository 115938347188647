.stamp {
    .ant-typography {
        color: $black;
    }
}

.print-exporting-detail {
    .sku-info .sku-code:before {
        content: '';
        position: static;
        flex-grow: 0;
        margin: 0 8px;
        height: 4px;
        width: 4px;
        background-color: #262338;
        border-radius: 50%;
        display: inline-block;
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
    }
}

@media print {
    html,
    body {
        width: auto !important;
        height: auto !important;
        color: black !important;
    }
}

.generate-item {
    font-size: 0.625rem;
    text-align: center;

    .sku-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}

.print-page-break {
    page-break-before: always;
}

.orders-table {
    .ant-table-thead .ant-table-cell {
        border-bottom: 1px solid #f0f0f0;
        color: #000000 !important;
        font-size: 16px !important;
    }
    .ant-table-tbody .ant-table-cell {
        color: #000000 !important;
        font-size: 16px !important;
    }
}

._shipping-stamp {
    color: #000000;
    font-size: 16px;
}

._packing-slip-stamp {
    color: #000000;
    font-size: 10px;
    .packing-slip-table {
        .ant-table-thead .ant-table-cell {
            border-bottom: 1px solid #f0f0f0;
            color: #000000 !important;
            font-size: 10px !important;
            padding: 3px 3px !important;
        }

        .ant-table-tbody .ant-table-cell {
            color: #000000 !important;
            font-size: 10px !important;
            padding: 3px 3px !important;
        }

        .ant-table-tbody .ant-table-cell:nth-child(4) {
            overflow: hidden;
            text-overflow: " ";
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }
}

.mobile-tab {
	background: #F7F7FC;
	position: fixed; top: 0; left: 0;
	width: 100%; height: 100%;
	z-index: 3;
	padding: 80px 10px 10px 10px;
	transform: translateX(100%);
	transition: all .3s;
	&.mobile-tab--shown {
		transform: translateX(0);
	}
	.page-header {
		box-shadow: none;
	}
}

.document-select-all-multi {
    background-color: #ebeffa !important;
    color: #000000d9 !important;
    font-weight: 600 !important;
    .ant-select-item-option-content {
        display: flex;
        justify-content: space-between;
        svg {
            fill: #4551bb;
        }
    }
}

.is-drop-down {
	.gobiz-dropdown {
		position: absolute; top: 100%; right: 0;
		box-shadow: 0 0 15px rgba(0,0,0,.1);
	}
}
.gobiz-dropdown {
	float: left;
	overflow: hidden;
	background: #ffffff;
	&--hide {
		display: none;
	}
	&--container {
		width: 200%;
		float: left;
		transition: all .3s;
		position: relative;
		&--moved {
			transform: translateX(-50%);
			.gobiz-dropdown--main {
				position: absolute;
			}
		}
	}
	&--main {
		float: left;
		width: 50%;
		ul {
			padding: 0 20px;
			li {
				line-height: 36px;
				padding: 9px 0;
				&+li {
					border-top: 1px solid #eeeeee;
				}
				a {
					color: $config-menu-text-color;
					&:hover {
						color: $color--primary;
					}
				}
				span {
					color: #4E4B66;
				}
			}
		}
	}
	&--item {
		float: right;
		width: 50%;
		opacity: 0;
		position: absolute;
		right: 0;
		z-index: 1;
		&.gobiz-dropdown--active {
			opacity: 1;
			z-index: 2;
			position: relative;
		}
		>p:first-child {
			padding: 7px 10px;
			line-height: 36px;
		}
	}
}

.upload-list-item {
    align-items: center;
    background-color: #eaf6f4;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;

    .anticon-file-excel {
        color: #06c675;
    }

    .ant-typography {
        margin-left: 4px;
    }
}

._span--delete {
    background: #e0e0e0;
    color: #ffffff;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    border-radius: 100%;
    &:hover {
        background: $color--secondary;
        color: $color--primary;
    }
}
.import-by-purchase-order-table {
    .ant-table-title {
        padding-top: unset;
        padding-bottom: unset;
    }
}
.number-circle {
    min-width: 20px;
    //height: 20px;
    line-height: 20px;
    border-radius: 20px;
    margin: 0px 4px;
    padding: 0 5px;
    background: #eaeeff;
    color: #4551bb;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
}
._span--add {
    background: $color--secondary;
    color: $color--primary;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    width: 20px;
    height: inherit;
    font-size: 10px;
    border-radius: 100%;
    &:hover {
        background: $color--primary;
        color: $txt-color--white;
    }
}

.menu-dropdown-action {
    border: 1px solid #d9dbe9;
    box-shadow: 0 6px 10px #eff0f6;
    border-radius: 4px;
    .ant-dropdown-menu-item {
        padding: 5px;
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background: #eaeeff;
        color: #4551bb;
    }
}

._packing-service-info .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    padding: 10px 0;
}
.btn-menu-item {
    border: unset !important;
    background: unset !important;
    width: 100%;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    &:hover,
    &:active,
    &:focus {
        color: #6976c7;
        border: unset !important;
        background: unset !important;
    }
}

.velaone {
    .site-content {
        background-color: #f9f9f9;
    }

    .main-menu {
        background: #6f6f71;
    }
    .main-menu .main-menu--list--title {
        color: #ffffff;
    }
    .main-menu--list--item a {
        color: #efefff;
    }

    .main-menu--member {
        background: #fff;
    }
    .main-menu--list--item a.selected,
    .main-menu--list--item:hover {
        background: #e8262d !important;
        a {
            color: #ffffff;
        }
    }

    .main-menu--member--btn span,
    .main-menu--member--btn span::before,
    .main-menu--member--btn span::after {
        color: #e8262d;
        border-color: #e8262d;
    }

    .ant-btn-primary {
        background: #e8262d;
        border-color: #e8262d;
    }
    .ant-btn-primary[disabled] {
        background: #f5f5f5;
        border-color: #d9d9d9;
    }

    .ant-btn-primary:hover {
        background: #cf1219;
        border-color: #e8262d;
    }

    a {
        color: #e8262d;
    }
    a:hover {
        color: #cf1219;
    }

    .ant-tabs-tab-active > .ant-tabs-tab-btn {
        color: #e8262d !important;
    }

    .number-circle {
        color: #e8262d;
    }
}

.btn-modal-re {
    background: #4551bb;
    border-color: #4551bb;
    &:focus {
        background: #4551bb;
        border-color: #4551bb;
    }
    &:hover {
        background: #6976c7;
        border-color: #6976c7;
    }
}

.custom-confirm-modal {
    .confirm-btn-group {
        margin-top: 24px;
        text-align: right;
    }
    .ant-modal-header {
        border: unset !important;
    }
    .ant-modal-body {
        padding: 42px 36px 30px !important;
    }
    .ant-modal-footer {
        display: none !important;
    }
    .confirm-body {
        color: #000000d9;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
        .anticon-exclamation-circle {
            color: #faad14;
            float: left;
            margin-right: 16px;
            font-size: 22px;
        }
    }
}
.w-content {
    width: fit-content;
}

.text {
    &-delete {
        color: #dd141b;
        &:hover {
            color: #f37074;
        }
    }
    &-save {
        color: #4551bb;
        &:hover {
            color: #6976c7;
        }
    }
   
}

.combo-badge{
    background: #72E08A;
    padding: 1px 8px;
    border-radius: 8px;
    font-size: 14px;
    display: inline-block;
}

.combo-tag {
    background: #e0f9f7;
    color: #09b2aa;
    padding: 0px 10px;
    border-radius: 4px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
$color--primary: #4551BB;
$color--secondary: #EAEEFF;
$color--border:#d8d8d8;
$color--font-primary:#333333;
$color--red-c:#ce3c32;
$color--gray-9:#999999;
$color--warning:#ffc107;
$color--green:#0780FF;
$color--boder-cp:rgba(242, 242, 242, 1);;
$color-blue-6:#0066FF;
$color--green-0:#006600;
$color-gray-c:#CCCCCC;
$bg-color--white: #ffffff;
$bg-color--grayf2: #f2f3f8;
$bg-color--grayf22: #f2f2f2;
$bg-color--grayf9: #f9faff;
$bg-color--grayc:#CCCCCC;

$color-button-primary: #0050B3;
$color-input-primary: #0050B3;

$txt-color-primary:#4551BB;
$txt-color--gray3: #333333;
$txt-color--gray4: #444444;
$txt-color--gray5: #555555;
$txt-color--gray6: #666666;
$txt-color--gray7: #777777;
$txt-color--gray8: #888888;
$txt-color--gray9: #999999;
$txt-color--white: #ffffff;
$txt-color--blue0: #0066FF;
$txt-color--grayc: #cccccc;
$txt-color--redc: #ce3c32;
$txt-color--yellow: #ffc107;
$txt-color--green0: #006600;
$txt-color--green8: #008800;
$txt-color--gray49: #495057;
$txt-color--redf: #ff0000;
$txt-color--label: #6E7191;

$border-color--grayd8: #d8d8d8;
$border-color--blue8: #80bdff;
$border-color--redf: #ff2725;
$border-color--gray9: #999999;
$border-color--grayf2f2: #f2f2f2;

$color--new-status: #349700;
$color--received-status: #0780FF;
$color--pending-status: #f27b00;
$color--in_progress-status: #b83700;
$color--completed-status: #25b8b4;
$color--closed-status: rgb(89, 89, 89);


@import './../../../../../resources/scss/bootstrap/functions';
@import './../../../../../resources/scss/bootstrap/variables';
@import './../../../../../resources/scss/bootstrap/mixins/breakpoints';

.scan-packing-purchasing-package {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    @include media-breakpoint-up(xl) {
        display: grid;
        grid-auto-columns: 2fr 1fr;
        grid-template-areas:
            'header header'
            'order         order'
            'top-main      aside'
            'bottom-main   aside';
    }

    .header {
        grid-area: header;

        :global(h3.ant-typography) {
            margin-bottom: 26px;
        }
    }

    .order {
        grid-area: order;

        :global(h3.ant-typography) {
            margin-bottom: 26px;
        }
    }


    .main {
        grid-area: bottom-main;
    }

    :global(.scan-errors) {
        grid-area: top-main;
    }

    .aside {
        grid-area: aside;

    }
}

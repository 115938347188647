.update-package-table {
    .ant-table-tbody {
        box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.4);
        padding: 1px;
    }
    .ant-table-thead > tr > th {
        color: #ffffff !important;
        background-color: $color--primary !important;
    }
    table {
        tbody {
            border-bottom: 1px solid #e8e8e8;
            tr {
                td {
                    border-bottom: 0;
                    border-top: 1px solid #e8e8e8;
                    vertical-align: top;
                }

                &.ant-table-expanded-row {
                    background-color: white;
                    td {
                        border-top: 0;
                        background: white;
                        padding-top: unset !important;
                    }
                }
                &:hover {
                    $b1: attr(data-row-key);
                }
            }
        }
    }
}

.update-package {
    .ant-input-disabled {
        background: white !important;
        color: rgba(16, 16, 16, 0.69);
    }
    .ant-input-number-disabled {
        background: white !important;
        color: rgba(16, 16, 16, 0.69);
    }
    .ant-radio-disabled {
        .ant-radio-inner {
            background: white !important;
            color: rgba(16, 16, 16, 0.94);
            border-color: black !important;
            &:after {
                background-color: black !important;
            }
        }
    }
    .ant-radio-disabled + span {
        color: rgba(16, 16, 16, 0.69);
    }

    .package-info {
        background-color: rgba(108, 117, 125, 0.16);
    }
}
td.ant-table-cell.avatar-shipping-partner {
    padding-right: 0 !important;
}

._service-list-info,
._package-order-info {
    .ant-table-title {
        padding: unset;
    }
}

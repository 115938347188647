.service-manager {
    &-tabs {
        .ant-tabs-tab {
            font-weight: 500;
            color: rgb(110, 110, 110);
            margin-right: 12px !important;
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
        }
        .ant-tabs-nav {
            margin-bottom: 8px !important;
            margin-top: 24px !important;
        }
        .ant-tabs-tabpane {
            // padding: 12px;
            padding-top: 0;
        }
    }
}
.service-combo-list,
.service-pack-list-table {
    table {
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
    }
    .ant-table-thead > tr > th {
        color: #ffffff !important;
        background-color: #77c4f8 !important;
    }
}

.import-detail-info {
    p {
        width: 40%;
    }
}
.form-table-price-product {
    .table-thead:first-child .ant-col:last-child {
        border-top-right-radius: 2px;
    }
    .table-thead > .ant-col,
    .table-tbody > .ant-col {
        border-right: 1px solid #f0f0f0;
        padding: 10px !important;
        border-bottom: 1px solid #f0f0f0;
    }
    .table-thead > .ant-col {
        font-weight: 500;
        text-align: left;
        background: #fafafa;
        transition: background 0.3s ease;
    }
    .table-tbody > .action {
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-right: unset !important;
    }
}

.product-options {
    .option-values {
        display: flex;
        flex-wrap: wrap;
    }

    .ant-tag {
        background-color: $white-lilac;
        display: inline-flex;
        margin-right: 0;
        min-width: 155px;
        padding: 5px 8px 5px 10px;

        .ant-tag-close-icon {
            color: #d9dbe9;
            margin-left: auto;
        }
    }
}
tr.ant-table-row.order-packing-disable {
    opacity: 0.3;
}

.tab-list-product {
    // border-radius: 8px;
    height: unset;
    font-weight: 500;
    padding: 8px 12px;
    min-width: 100px;
    &:hover,
    &:focus {
        color: #000000d9;
        border-color: #d9d9d9;
        background: #dddada;
    }

    &.active {
        background: #dddada;
        // color: #fff;
        border-color: #dddada;
    }

    &-quantity {
        padding: 1px 8px;
        color: #fcb823;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        background: #f9f1e0;
        border-radius: 20px;
    }
}

.table-parent {
    .ant-table-expanded-row.ant-table-expanded-row-level-1 {
        > td {
            background: #d1d1d12f;
        }
        table {
            box-shadow: 0px 0px 5px rgba(139, 139, 139, 0.377);
            tr {
                th {
                    background: #dbe0ff9e;
                }
            }
        }
        td.td-border-many{
            padding: 15px 0px !important;
            .child-item{
                border-top: 1px solid #e5e7f1;
                padding-bottom: 12px;
                padding-top: 12px;
                padding-left: 10px;
                &:first-child{
                    padding-top: 0;
                    border-top: unset;
                }
                &:last-child{
                    padding-bottom: 0px;
                }
            }
           
        }
        td.td-border-right{
            border-right: 1px solid #e5e7f1;
        }
    }
}

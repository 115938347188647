@import './../../../../../resources//scss/bootstrap/functions';
@import './../../../../../resources//scss/bootstrap/variables';
@import './../../../../../resources//scss/bootstrap/mixins/breakpoints';

.inventory-preview {
    display: grid;
    grid-gap: 16px;
    padding: 32px 24px;
    grid-template-areas:
        'left'
        'right';

    @include media-breakpoint-up(xl) {
        grid-auto-columns: 2fr 1fr;
        grid-template-areas: 'left right';
    }

    .barcode-list {
        grid-area: left;
    }

    .summary {
        grid-area: right;
    }
}

@import './../../../../../resources/scss/bootstrap/functions';
@import './../../../../../resources/scss/bootstrap/variables';
@import './../../../../../resources/scss/bootstrap/mixins/breakpoints';

.inventory-detail {
    display: grid;
    grid-gap: 16px;
    grid-template-areas:
        'header'
        'main'
        'top-aside'
        'bottom-aside';

    @include media-breakpoint-up(xl) {
        grid-auto-columns: 2fr 1fr;
        grid-template-areas:
            'header header'
            'main   top-aside'
            'main   bottom-aside';
    }

    .header {
        grid-area: header;

        :global(h3.ant-typography) {
            margin-bottom: 26px;
        }
    }

    :global(.inventory-document-barcode-list) {
        grid-area: main;
    }

    .scan-histories {
        grid-area: top-aside;

        :global(.ant-input) {
            width: 209px;
        }
    }

    :global(.document-inventory-summary) {
        grid-area: bottom-aside;
    }
}

.range-input {
    :global(.ant-form-item) {
        width: calc(50% - 20px);
        text-align: center;
        margin-bottom: 0;

        &:nth-child(1) {
            :global(input.ant-input) {
                border-radius: 4px 0 0 4px;
                border-right: none;
            }
        }

        &:nth-child(3) {
            :global(input.ant-input) {
                border-radius: 0 4px 4px 0;
                border-left: none;
            }
        }
    }
    :global(input.separator) {
        width: 40px;
        text-align: center;
        border-right: none;
        border-left: none;
    }
}

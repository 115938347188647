.box {
    background-color: #fff;
    border-radius: 12px;
    padding: 24px;

    :global(h3.ant-typography) {
        font-size: 16px;
        margin-bottom: 0;
    }
}

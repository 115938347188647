@import "./../../../../resources/scss/settings/setting.color";

.table-count {
    align-items: center;
    background-color: $color--secondary;
    border-radius: 20px;
    color: $color--primary;
    display: inline-flex;
    font-size: 12px;
    font-weight: normal;
    justify-content: center;
    padding: 3px 8px;
}

.modal-dropshipping-detail-create_price {
    @include media-breakpoint-up(xs) {
        .ant-modal-body {
            padding: 0;
        }
    }
}
._dropship-service-list_new-history {
    @include media-breakpoint-up(xs) {
        .customized-pagination {
            padding: 0 !important;
        }
    }
}

.btn-modal_ok {
    &:focus {
        color: #fff;
        background: #4551bb;
        border-color: #4551bb;
    }
    &:hover {
        color: #fff;
        background: #6976c7;
        border-color: #6976c7;
    }
}

._dropship-service_btn_cancel {
    &:hover {
        color: #ff7875;
        border-color: #ff7875;
    }
}

._dropship-service-list_combo-table {
    .ant-form-item-explain.ant-form-item-explain-error {
        position: absolute;
        bottom: -25px;
        > div {
            white-space: nowrap;
        }
    }
    .ant-table-tbody > tr > td {
        // padding: 20px 10px !important;
        padding-bottom: 22px !important;
    }
}
